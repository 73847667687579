import { Center, Stack, Text } from "@chakra-ui/layout";
import React from "react";
import { useTranslation } from "react-i18next";
const About = () => {
  const {t} = useTranslation();
  return (
    <>
      <Stack spacing={6}>
        <Center color="Black">
          <Text
            fontSize={"xl"}
            justifyContent={"flex"}
            paddingLeft={"10%"}
            paddingRight={"10%"}
          >{ t('about') }
            {/* Global Trading is a Pakistan based company operating in more than a
            dozen countries in Eastern, Central and Western parts of Africa. We
            have participated in trade fairs in several countries across Africa.
            We are dealing in hand made Ladies Shoes, Kitchenware, Leather
            products, Fabric and other Pakistan made products. */}
          </Text>
        </Center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </Stack>
    </>
  );
};

export default About;
