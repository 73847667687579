import { Container, Grid, Text } from "@chakra-ui/layout";
import {
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { fetchData } from "../clinet";
const Shoes = () => {
  const param = useParams();
  const name = param;
  console.log(name);
  const [shoes, setShoes] = useState([]);
  // const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState();

  useEffect(() => {
    setLoading(true);
    fetchData("shoes")
      .then((response) => setShoes(response))
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, [name]);
  // const filtering =(value) => {
  //   if (name === "") {
  //     return value;
  //   } else if (
  //     value.fields.title.toLowerCase().includes(name.toLowerCase())
  //   ) {
  //     return value;
  //   }
  //   return false
  // }

  console.log(shoes);

  if (loading) return <div>Loading ...</div>;
  return (
    <Container maxW="container.xl">
      <Grid
        my="4"
        gap={8}
        gridTemplateColumns="repeat(auto-fit,minmax(80px,2fr))"
      >
        {shoes
          // .filter((shoe)=>{return(filtering(shoe))})
          .map((shoe, index) => (
            <>
              <Flex justify="center" align="center">
                <Popover>
                  <PopoverTrigger>
                    <Image
                      src={`https:${shoe.fields.images[0].fields.file.url}`}
                      alt={shoe.fields.title}
                      flex={"1"}
                      height={"100%"}
                      width={"100%"}
                      objectFit={"cover"}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverHeader>
                        <Text fontWeight={500}>
                          {" "}
                          Article No# {shoe.fields.articleNumber}
                        </Text>
                      </PopoverHeader>
                      <PopoverCloseButton />
                      <PopoverBody box>
                        <a
                          href={`https:${shoe.fields.images[0].fields.file.url}`}
                          rel="noreferrer"
                          alt={shoe.fields.articleNumber}
                          target={"_blank"}
                        >
                          <Image
                            src={`https:${shoe.fields.images[0].fields.file.url}`}
                            alt={shoe.fields.title}
                            height={"500px"}
                          />
                        </a>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Flex>
            </>
          ))}
      </Grid>
    </Container>
  );
};

export default Shoes;
