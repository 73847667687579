import React from "react";
import { Link } from "react-router-dom";
import { Container, Grid } from "@chakra-ui/layout";
import { Flex, Image, Stack, Text, VStack } from "@chakra-ui/react";
const Products = () => {
  return (
    <div>
      Products
      <hr />
      <ul>
        <li>
          <Link to="/shoes">
            <Container maxW="container.xl">
              <Grid
                my="8"
                gap={4}
                gridTemplateColumns="repeat(auto-fit,minmax(320px,1fr))"
              >
                <>
                  <Flex justify="center" align="center">
                    <Stack width="30%" boxShadow="md" borderRadius="xl">
                      <Image src="footware.jpg" alt="footware" />
                      <Stack p="2">
                        <VStack>
                          <Text fontWeight="600">Footware</Text>
                        </VStack>
                      </Stack>
                    </Stack>
                  </Flex>
                </>
              </Grid>
            </Container>
          </Link>
        </li>
        <li>
          <Link to="/kitchen">Kitchen</Link>
        </li>
      </ul>
    </div>
  );
};

export default Products;
