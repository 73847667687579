import React from "react";
import {
  Center,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import logo from "../logo.png";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

const changeLang = (l) => {
  //alert('OK');
  return () => {
    //alert('ok '+l);
    //Now change the language
    //object.member();
    i18next.changeLanguage(l);

    //Now set the current language in local storage
    localStorage.setItem("lang", l);
  };
};

function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 0 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: -1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 0, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={"bold"}
            color={"black"}
            bg={"white"}
            // variant={"link"}
            href={"#"}
            _hover={{
              bg: "white",
            }}
            onClick={changeLang("en")}
            leftIcon={
              <img
                src="https://flagcdn.com/w20/us.png"
                width={"40px"}
                alt="flag"
              />
            }
          >
            English
          </Button>
          <Button
            display={{ base: "none", md: "inline-flex" }}
            fontSize={"sm"}
            fontWeight={"bold"}
            color={"black"}
            bg={"#FFFFFF"}
            href={"#"}
            _hover={{
              bg: "#FFFFFF",
            }}
            onClick={changeLang("fr")}
            leftIcon={
              <img
                src="https://flagcdn.com/w20/fr.png"
                width={"40px"}
                alt="flag"
              />
            }
          >
            Français
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

// const DesktopSubSubNav = ({ label, href }) => {
//   return (
//     <Link
//       to={href}
//       role={"group"}
//       display={"block"}
//       p={2}
//       rounded={"md"}
//       _hover={{ bg: useColorModeValue("#f58442", "gray.900") }}
//     >
//       <Stack direction={"row"} align={"center"}>
//         <Box>
//           <Text
//             transition={"all .3s ease"}
//             _groupHover={{ color: "#f58442" }}
//             fontWeight={500}
//           >
//             {label}
//           </Text>
//           {/* <Text fontSize={"sm"}>{subLabel}</Text> */}
//         </Box>
//         <Flex
//           transition={"all .3s ease"}
//           transform={"translateX(-10px)"}
//           opacity={0}
//           _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
//           justify={"flex-end"}
//           align={"center"}
//           flex={1}
//         >
//           <Icon color={"#f58442"} w={5} h={5} as={ChevronRightIcon} />
//         </Flex>
//       </Stack>
//     </Link>
//   );
// };

const DesktopSubNav = (props) => {
  // const linkColor = useColorModeValue("gray.600", "gray.200");
  // const linkHoverColor = useColorModeValue("gray.800", "white");
  // const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <>
      <Menu px={4} size={"small"}>
        <Link to={props.href}>
          <MenuButton
            px={8}
            py={2}
            bg={"gray.200"}
            color={"black"}
            // w={"130px"}
            textAlign={"left"}
            transition="all 0.2s"
            borderRadius="md"
            borderWidth="1px"
            _hover={{ bg: "gray.200" }}
            _expanded={{ bg: "gray.200" }}
            _focus={{ boxShadow: "outline" }}
          >
            {props.label}
            {/* <ChevronDownIcon /> */}
          </MenuButton>
        </Link>
        {/* <MenuList bgColor={"gray.200"}>
          {props.children.map((child) => (
            <>
              <Link to={child.href}>
                {" "}
                <MenuItem color={"black"} width>{child.label}</MenuItem>
              </Link>
            </>
          ))}
        </MenuList> */}
      </Menu>
    </>
  );
};

const DesktopNav = () => {
  const { t } = useTranslation();
  const linkColor = useColorModeValue("black.600", "white.200");
  const linkHoverColor = useColorModeValue("white.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "white.800");
  const NAV_ITEMS = [
    {
      label: `${t("home")}`,
      href: "/",
    },
    {
      label: `${t("products")}`,
      children: [
        {
          label: `${t("footwear")}`,
          href: "/shoes/",
          children: [
            {
              label: `${t("pumps")}`,
              // subLabel: "Trending Design to inspire you",
              href: "/shoes/pump",
            },
            {
              label: `${t("kolhapuri")}`,
              // subLabel: "Up-and-coming Designers",
              href: "/shoes/kolhapuri",
            },
          ],
        },
        {
          label: `${t("bbq")}`,
          href: "/kitchen",
          children: [
            {
              label: `${t("bbq")}`,
              // subLabel: "An exclusive list for contract work",
              href: "/kitchen/barbeque",
            },
            {
              label: `${t("steamer")}`,
              // subLabel: "Find your dream design job",
              href: "/kitchen/steamer",
            },
          ],
        },
      ],
    },
    {
      label: `${t("events")}`,
      href: "/events",
    },
    {
      label: `${t("about_us")}`,
      href: "/about",
    },
    {
      label: `${t("contact_us")}`,
      href: "/contact",
    },
  ];
  return (
    <Stack direction={"row"} spacing={16}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                to={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                <Text color={"black"} fontWeight={"bold"}>
                  {navItem.label}
                </Text>
              </Link>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = () => {
  const NAV_ITEMS = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Products",
      children: [
        {
          label: "Footwear",
          children: [
            {
              label: "Pumps Shoes",
              // subLabel: "Trending Design to inspire you",
              href: "/shoes/pump",
            },
            {
              label: "Kolhapuri Slippers",
              // subLabel: "Up-and-coming Designers",
              href: "/shoes/kolhapuri",
            },
          ],
        },
        {
          label: "Kitchenware",
          children: [
            {
              label: "Steamer Cooker",
              // subLabel: "Find your dream design job",
              href: "/kitchen/steamer",
            },
            {
              label: "Barbeque Maker",
              // subLabel: "An exclusive list for contract work",
              href: "/kitchen/barbeque",
            },
          ],
        },
      ],
    },
    {
      label: "Events",
      href: "/events",
    },
    {
      label: "About Us",
      href: "/about",
    },
    {
      label: "Contact Us",
      href: "/contact",
    },
  ];
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? "#"}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                <Menu>
                  <MenuButton
                    px={4}
                    py={2}
                    transition="all 0.2s"
                    borderRadius="md"
                    borderWidth="1px"
                    _hover={{ bg: "gray.400" }}
                    _expanded={{ bg: "blue.400" }}
                    _focus={{ boxShadow: "outline" }}
                  >
                    {child.label} <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    {child.children.map((childe) => (
                      <>
                        <Link to={child.href}>
                          {" "}
                          <MenuItem>{childe.label}</MenuItem>
                        </Link>
                      </>
                    ))}
                  </MenuList>
                </Menu>
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const Navbar = () => {
  return (
    <>
      <Center>
        <HStack>
          <Box>
            <Image
              boxSize="90px"
              objectFit="cover"
              src={logo}
              alt="Logo"
              ml={"10px"}
              mt={"10px"}
            />
          </Box>
          <br />
          <br />
          <br />
          <Box>
            <Text fontSize="xxx-large" mt={"7%"} paddingLeft={"3"}>
              <b>Global</b> Trading
            </Text>
          </Box>
          <br />
          <br />
          <br />
        </HStack>
      </Center>
      <br />

      <WithSubnavigation />
    </>
  );
};

export default Navbar;
