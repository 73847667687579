import React from "react";
import {
  Box,
  Heading,
  Link,
  Image,
  Text,
  Divider,
  Wrap,
  WrapItem,
  useColorModeValue,
  Container,
  Center,
} from "@chakra-ui/react";
import fidak from "../assets/fidak-min.png";
import arton from "../assets/arton16341.jpg";
import { useTranslation } from "react-i18next";
const Events = () => {
  const { t } = useTranslation();
  return (
    <>
      <Container maxW={"7xl"} p="8">
        <Box
          display="flex"
          paddingRight={"15%"}
          flexDirection={{ base: "column", sm: "row" }}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            paddingLeft={"20%"}
            flex="1"
            marginRight="3"
            position="relative"
            alignItems="center"
          >
            <Image blockSize={"70%"} src={fidak} alt="fidak" />
          </Box>
          <Box
            display="flex"
            flex="1"
            flexDirection="column"
            justifyContent="center"
            marginTop={{ base: "3", sm: "0" }}
          >
            <Heading marginTop="1" fontSize={"25px"}>
              {t("upcoming_event")}
            </Heading>
            <Text
              as="p"
              marginTop="2"
              color={useColorModeValue("gray.700", "gray.200")}
              fontSize="lg"
              display={"flex"}
              justifyContent={"center"}
            >
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {t("upcoming_text")}
            </Text>
          </Box>
        </Box>
        <hr />
        <br />

        <Center>
          <Image boxSize={"80%"} src={arton} alt="Arton" />
        </Center>
        <br />
        <Center color="Black">
          <hr />
          <Center color="Black" boxSize={"80%"}>
            <Text fontSize={"xl"} display={"flex"} justifyContent={"center"}>
              {t("upcoming_events_text")}
            </Text>
          </Center>
        </Center>
        {/* <hr /> */}
        <Divider marginTop="5" />

        <Center>
          <Heading marginTop="1" fontSize={"25px"}>
            {t("past_events")}
          </Heading>
        </Center>
        <br />
        <Text fontSize={"20px"}>{t("past_events_heading_text")}</Text>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  RWANDA
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                {t("rawanda_1")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  CAMEROON
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                {t("cameron_1")}
                <br />
                {t("cameron_2")}
                <br /> {t("cameron_3")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  NIGER
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Foire Ramadan Niamey
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  BURKINA FASO
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                SIAO - Salon Internationale de l&#39;Artisanat de Ouagadougou
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  TOGO
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Foire Internationale de Lome
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  BENIN
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                Foire Internationale de Parakou <br />
                Foire De Cotonou <br />
                Foire Independence Cotonou
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  SENEGAL
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                {t("senigal_1")}
                <br />
                {t("senigal_2")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  MALI
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                FEBAK - Foire Internationale de Bamako
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  TANZANIA
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                SabaSaba International Trade Fair Dar es Salaam <br />
                Syrian Exhibition Diamond Jubilee Dar es Salaam
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
        <Divider marginTop="5" />

        <Wrap spacing="30px" marginTop="5">
          <WrapItem
            width={{ base: "100%", sm: "100%", md: "100%", lg: "100%" }}
          >
            <Box w="100%">
              <Heading fontSize="xl" marginTop="2">
                <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                  IRAQ
                </Link>
              </Heading>
              <Text as="p" fontSize="md" marginTop="2">
                {t("iraq")}
              </Text>
            </Box>
          </WrapItem>
        </Wrap>
      </Container>
    </>
  );
};

export default Events;
