import {
  Container,
  Flex,
  Box,
  IconButton,
  Button,
  VStack,
  HStack,
  Wrap,
  WrapItem,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Link,
  Center,
  Text,
} from "@chakra-ui/react";
import { MdPhone, MdEmail, MdFacebook, MdOutlineEmail } from "react-icons/md";
import { BsInstagram, BsPerson, BsWhatsapp } from "react-icons/bs";

// import emailjs from "@emailjs/browser";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

// const { EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, EMAILJS_PUBLIC_ID } =
//   process.env;

const Contact = () => {
  // const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  // const [loading, setLoading] = useState(false);
  const form = useRef();
  const { t } = useTranslation();

  // const sendEmail = (e) => {
  //   e.preventDefault();
  //   console.log("sending email");
  //   setLoading(true);
  //   setIsFormSubmitted(true);
  //   emailjs
  //     .sendForm({ EMAILJS_SERVICE_ID }, { EMAILJS_TEMPLATE_ID }, form.current, {
  //       EMAILJS_PUBLIC_ID,
  //     })
  //     .then(
  //       (result) => {
  //         console.log(result.text);
  //       },
  //       (error) => {
  //         console.log(error.text);
  //       }
  //     );
  //   setLoading(false);
  // };
  return (
    <Container bg="white" maxW="full" mt={0} centerContent overflow="hidden">
      <Flex alignItems="center">
        <Box
          bg="gray.200"
          color="white"
          borderRadius="lg"
          m={{ sm: 4, md: 16, lg: 10 }}
          p={{ sm: 5, md: 5, lg: 16 }}
          alignItems="center"
        >
          <Box p={4} alignItems="center" paddingRight={"130px"}>
            <Wrap
              spacing={{ base: 20, sm: 3, md: 5, lg: 20 }}
              alignItems="center"
            >
              <WrapItem alignItems="center">
                <Box>
                  {/* <Heading>
                  </Heading> */}
                  {/*<Text mt={{ sm: 3, md: 3, lg: 5 }} color="white.500">
                    Fill up the form below to contact
                  </Text> */}
                  {/* <center>
                      <Button
                        size="md"
                        height="48px"
                        width="200px"
                        variant="ghost"
                        color="black"
                        _hover={{ border: "2px solid #f58442" }}
                        leftIcon={<MdPhone color="#1970F1" size="20px" />}
                      >
                        +92 300 6358658
                      </Button>
                      <Button
                        size="md"
                        height="48px"
                        width="300px"
                        variant="ghost"
                        color="black"
                        _hover={{ border: "2px solid #f58442" }}
                        leftIcon={<MdEmail color="#1970F1" size="20px" />}
                      >
                        globaltrading.pk@yahoo.com
                      </Button></center> */}
                  <hr />
                  <Box py={{ base: 5, sm: 5, md: 8, lg: 10 }}>
                    <VStack pl={50} spacing={3}>
                      {/* <Button
                        size="md"
                        height="48px"
                        width="200px"
                        variant="ghost"
                        color="#DCE2FF"
                        _hover={{ border: '2px solid #1C6FEB' }}
                        leftIcon={<MdLocationOn color="#1970F1" size="20px" />}>
                        Faislabad, Pakistan
                      </Button> */}
                    </VStack>
                  </Box>
                  <br />
                </Box>
              </WrapItem>
              <WrapItem>
                <Box bg="white" borderRadius="lg" w={"100%"}>
                  <Box m={8} color="#0B0E3F">
                    <VStack spacing={5}>
                      <FormControl
                        id="name"
                        ref={form}
                        onSubmit={(e) => {
                          e.preventDefault();
                          // sendEmail();
                        }}
                      >
                        <FormLabel>{t("name")}</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<BsPerson color="gray.800" />}
                          />
                          <Input type="text" size="md" name="from_name" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>{t("email")}</FormLabel>
                        <InputGroup borderColor="#E0E1E7">
                          <InputLeftElement
                            pointerEvents="none"
                            children={<MdOutlineEmail color="gray.800" />}
                          />
                          <Input type="text" size="md" name="from_email" />
                        </InputGroup>
                      </FormControl>
                      <FormControl id="name">
                        <FormLabel>{t("message")}</FormLabel>
                        <Textarea
                          borderColor="gray.300"
                          _hover={{
                            borderRadius: "gray.300",
                          }}
                          placeholder="message"
                          name="message"
                        />
                      </FormControl>
                      <FormControl id="name" float="right">
                        <Button
                          variant="solid"
                          type="submit"
                          bg="#0D74FF"
                          color="white"
                          _hover={{}}
                        >
                          {t("send_message")}
                        </Button>
                      </FormControl>
                    </VStack>
                  </Box>
                </Box>
              </WrapItem>
            </Wrap>
          </Box>
          <Center>
            <MdPhone color="#1970F1" size="20px" />
            <Text size="md" color={"black"}>
              +92 300 6358658 &nbsp; &nbsp; &nbsp;
            </Text>
            <MdPhone color="#1970F1" size="20px" />
            <Text size="md" color={"black"}>
              +92 333 4040487 &nbsp; &nbsp; &nbsp;
            </Text>
            <MdEmail color="#1970F1" size="20px" />
            <Text size="md" color={"black"}>
              globaltrading.pk@yahoo.com
            </Text>
          </Center>
          <Center>
            <HStack
              mt={{ lg: 10, md: 10 }}
              spacing={5}
              px={5}
              alignItems="flex-start"
            >
              <Link
                href="https://api.whatsapp.com/send?phone=923006358658"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="facebook"
                  variant="unstyled"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: "black" }}
                  icon={<BsWhatsapp size="40px" color="gray" />}
                />
              </Link>
              <Link
                href="mailto:globaltrading.pk@yahoo.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <IconButton
                  aria-label="facebook"
                  variant="unstyled"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: "black" }}
                  icon={<MdEmail size="40px" color="gray" />}
                />
              </Link>
              <Link
                href="https://www.facebook.com/Globaltradingdotpk"
                target="_blank"
              >
                <IconButton
                  aria-label="facebook"
                  variant="unstyled"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: "black" }}
                  icon={<MdFacebook size="40px" color="gray" />}
                />
              </Link>
              <Link
                href="https://www.instagram.com/_u/globaltradingdotpk"
                target="_blank"
              >
                <IconButton
                  aria-label="instagram"
                  variant="unstyled"
                  size="lg"
                  isRound={true}
                  _hover={{ bg: "black" }}
                  icon={<BsInstagram size="40px" color="gray" />}
                />
              </Link>
            </HStack>
          </Center>
        </Box>
      </Flex>
    </Container>
  );
};

export default Contact;
