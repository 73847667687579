import React, { useEffect, useState } from "react";
import { Box, Center, Stack, Text } from "@chakra-ui/react";
import Slider from "react-slick";
import { fetchData } from "../clinet";
import { useTranslation } from "react-i18next";
const settings = {
  dots: true,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const Home = () => {
  const [slider, setSlider] = React.useState(null);
  const [hImages, setImages] = useState([]);
  const [loading, setLoading] = useState();
  const { t } = useTranslation();
  console.log(slider);
  useEffect(() => {
    setLoading(true);
    fetchData("homePageImages")
      .then((response) => {
        setImages(response[0].fields.images);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  }, []);

  // console.log(hImages.map((img, ind)=>(img.fields.file.url)));

  if (loading) return <div>Loading ...</div>;
  return (
    <>
      <Center>
        <Box
          position={"relative"}
          height={"40%"}
          width={"80%"}
          overflow={"cover"}
        >
          {/* CSS files for react-slick */}
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />

          <Slider {...settings} ref={(slider) => setSlider(slider)}>
            {hImages.map((img, index) => (
              <Box
                key={index}
                height={"md"}
                position="relative"
                backgroundPosition="center"
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
                backgroundImage={`https:${img.fields.file.url}`}
              />
            ))}
          </Slider>
        </Box>
        <hr />
      </Center>
      <br />
      <br />

      <Stack spacing={6}>
        <Center color="Black">
          <Text
            fontSize={"2xl"}
            justifyContent={"stretch"}
            paddingLeft={"10%"}
            paddingRight={"10%"}
          >
            &nbsp; &nbsp; &nbsp; &nbsp;
            {t("home_text")}
            <br />
            <br />
            {t("home_desc_text")}
            <br />
            <br />• {t("home_1")}
            <br />• {t("home_2")}
            <br />• {t("home_3")}
            <br />• {t("home_4")}
            <br />• {t("home_5")}
            <br />
          </Text>
        </Center>
        <br />
        <br />
        <br />
      </Stack>
    </>
  );
};

export default Home;
