import {
  Container,
  Flex,
  Grid,
  Heading,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  // Text,
} from "@chakra-ui/react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { fetchData } from "../clinet";
// import Shoes from "./Shoes";

const Kitchen = () => {
  const [kitchenWear, setKitchen] = useState([]);
  const [loading, setLoading] = useState();
  useEffect(() => {
    setLoading(true);
    fetchData("kitchen")
      .then((response) => setKitchen(response))
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  }, []);

  // console.log( kitchenWear[0].fields.images[0]);
  console.log(kitchenWear);
  if (loading) return <div>Loading ...</div>;
  return (
    <Container maxW={"7xl"}>
      {kitchenWear.map((kitchen, index) => (
        <>
          <br />
          <br />
          <Heading
            lineHeight={1.1}
            fontWeight={500}
            // textStyle={""}
            fontSize={{ base: "2xl", sm: "3xl", lg: "3xl" }}
          >
            {kitchen.fields.title}
          </Heading>
          {/* <br />
          <Text justifyContent={"center"}>{kitchen.fields.description}</Text>
          <br /> */}
          <hr />
          <br />
          <Grid templateColumns="repeat(5, 1fr)" gap={6}>
            {kitchen.fields.images.map((item, j) => (
              <Flex justify="center" align="center">
                <Popover>
                  <PopoverTrigger>
                    <Image
                      src={`https:${item.fields.file.url}`}
                      alt={item.fields.title}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverCloseButton />
                      <PopoverBody>
                        <a
                          href={`https:${item.fields.file.url}`}
                          alt={item.fields.title}
                          rel="noreferrer"
                          target={"_blank"}
                        >
                          <Image
                            src={`https:${item.fields.file.url}`}
                            alt={item.fields.title}
                            height={"200%"}
                            w={"200%"}
                          />
                        </a>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Flex>
            ))}
          </Grid>
        </>
      ))}
      <br />
    </Container>
  );
};

export default Kitchen;

// {kitchen.fields.images.map((item, j) => (

//   <Flex justify="center" align="center">
//     <Image
//       key={index}
//       src={`https:${item.fields.file.url}`}
//       alt={item.fields.title}
//     />
//   </Flex>

// ))}
