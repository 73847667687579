
import { Container } from "@chakra-ui/layout";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import About from "./components/About";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Products from "./components/Products";
import Shoes from "./components/Shoes";
import Kitchen from "./components/Kitchen";
import Contact from "./components/Contact";
import Events from "./components/Events";
import { useEffect } from "react";
import i18next from "i18next";

function App() {

  useEffect(() => {
    //alert('Page is loaded successfully');
    //get the current language stored in the local storage
    let currentLang = localStorage.getItem('lang');
    i18next.changeLanguage(currentLang);

  }, [])
  return (
    <>
      <Router>
        <Navbar />
        <br />
        <br />
        <Switch>
          <Container maxW="container.xl">
            <Route exact path="/" component={Home} />
            <Route path="/products" component={Products} />
            <Route path="/shoes/" component={Shoes} />
            <Route path="/kitchen/" component={Kitchen} />
            <Route path="/about" component={About} />
            <Route path="/events" component={Events} />
            <Route path="/contact" component={Contact} />
            <Route
              path="*"
              components={() => (
                <h2>
                  404 not found <hr />
                  <Link to="/">Go to Home page</Link>
                </h2>
              )}
            />
          </Container>
        </Switch>
      </Router>

      <Footer />
    </>
  );
}

export default App;
