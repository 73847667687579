import * as contentful from "contentful";

export const Client = contentful.createClient({
  space: `${process.env.REACT_APP_SPACE_ID}`,
  accessToken: `${process.env.REACT_APP_ACCESS_TOKEN}`,
});
export const fetchData = async (value) => {
  const entries = await Client.getEntries({
    content_type: value,
    // "fields.slug": slug,
  });
  // console.log(entries.items);
  return entries.items;
};

// export const fetchDataWithFilter = async (value, word) => {

//   const entries = await Client.getEntries({
//     content_type: value,

//     select: word,
//   })
//   // console.log(entries.items);
//   return entries.items;

// }
